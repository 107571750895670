import { css, Global } from "@emotion/react";
import { type ReactElement } from "react";

export const GlobalStyles = (): ReactElement<typeof Global> => (
  <Global
    styles={css`
      body {
        font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, YuGothic,
          "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
      }
    `}
  />
);
