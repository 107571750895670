export const textPalette = {
  black: "#1F2C32",
  gray: {
    0: "#E8ECEF",
    1: "#D3D4D8",
    2: "#AFB0B5",
    3: "#849194",
  },
  white: "#FFFFFF",
  blue: "#2B8ADE",
} as const;

type TextBlackPalette = "text.black";
type TextGrayPalette = `text.gray.${keyof typeof textPalette.gray}`;
type TextWhitePalette = "text.white";

export type TextPalette = TextBlackPalette | TextGrayPalette | TextWhitePalette;
