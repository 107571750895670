import { type CurrentUser } from "@/generated/graphql.gen";
import { isProduction } from "@/utils/env";
import { KarteUtil } from "@/utils/karte";
import TagManager from "react-gtm-module";

export const EventUtil = {
  reportUser(user: CurrentUser | undefined | null) {
    if (!isProduction()) return;

    const userInfo = { user_id: user?.id };
    const tagManagerArgs = {
      dataLayer: userInfo,
    };
    TagManager.dataLayer(tagManagerArgs);
    KarteUtil.reportKarteUser(userInfo);
  },
  reportView() {
    if (!isProduction()) return;

    KarteUtil.reportView();
  },
};
