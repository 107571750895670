import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

// For Google Analytics
declare const window: unknown;

export const GtagUtil = {
  gtmId(): string {
    return (publicRuntimeConfig?.GTM_CONTAINER_ID as string) || "GTM-W9BX7HZ";
  },
  hasGTag() {
    return typeof window !== "undefined";
  },
};
