import { type CurrentUser } from "@/generated/graphql.gen";
import { type GlobalRole } from "@/pages/[username]/hooks/useCanShowPrivateComponent";
import { type NextRouter } from "next/router";

const allPaths = ["/[username]", "/me", "/me/edit", "/me/works/new", "/"] as const;
type Paths = (typeof allPaths)[number];

const allowedRolesByPath: Record<Paths, GlobalRole[]> = {
  "/[username]": ["isActiveUser", "isGeneralStaffUser", "isWorksAdminUser", "isWorksStaffUser", "isWorksClientUser"],
  "/me": ["isActiveUser", "isGeneralStaffUser", "isWorksAdminUser", "isWorksStaffUser"],
  "/me/edit": ["isActiveUser", "isGeneralStaffUser", "isWorksAdminUser", "isWorksStaffUser"],
  "/me/works/new": ["isActiveUser", "isGeneralStaffUser", "isWorksAdminUser", "isWorksStaffUser"],
  "/": ["isActiveUser", "isGeneralStaffUser", "isWorksAdminUser", "isWorksStaffUser"],
};
export const isAllowedPathForCurrentUser = (router: NextRouter, currentUser: CurrentUser | null): boolean => {
  if (!currentUser) {
    return false;
  }
  const allowedRoles = allowedRolesByPath[router.pathname as Paths];

  for (const role of allowedRoles) {
    if (currentUser[role]) {
      return true;
    }
  }
  return false;
};
