/* eslint-disable @typescript-eslint/no-explicit-any -- for KARTE v2 tag */
import { type UserInfo } from "@/types/utils";

declare const krt: (send: string, event: string, values?: any) => any;

export const KarteUtil = {
  reportKarteUser(userInfo: UserInfo) {
    if (this.hasKarte()) {
      krt("send", "identify", userInfo);
    }
  },
  reportView() {
    if (this.hasKarte()) {
      krt("send", "view");
    }
  },
  hasKarte() {
    return typeof krt !== "undefined";
  },
};
/* eslint-enable @typescript-eslint/no-explicit-any */
