import { isProduction, isStaging } from "@/utils/env";

export const likesUrl = (path: string): string => {
  if (isProduction()) {
    return `https://shelikes.jp/${path}`;
  }
  if (isStaging()) {
    return `https://staging.shelikes.jp/${path}`;
  }
  return `http://localhost:8090/${path}`;
};

export const socialProfileUrl = (path: string): string => {
  if (isProduction()) {
    return `https://profile.shelikes.jp/${path}`;
  }
  if (isStaging()) {
    return `https://staging.profile.shelikes.jp/${path}`;
  }
  return `/${path}`;
};
