import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios";
import { UserTokenApis } from "@/api/jwt";

type ParamType = Record<string, string | number | string[] | number[] | undefined>;

type Headers = Record<string, string>;

export interface SuccessResponse<T> {
  success: true;
  error: false;
  body: T;
}

export interface NotFoundErrorResponse<T> {
  error: true;
  code: "NOT_FOUND";
  body?: T;
}

export interface InvalidErrorResponse<T> {
  error: true;
  code: "INVALID";
  body?: T;
}

export interface UnauthorizedErrorResponse<T> {
  error: true;
  code: "UNAUTHORIZED";
  body?: T;
}

export interface OperationalErrorResponse<T> {
  error: true;
  code: "OPERATIONAL";
  body?: T;
}

export type ErrorResponse<T> =
  | NotFoundErrorResponse<T>
  | InvalidErrorResponse<T>
  | OperationalErrorResponse<T>
  | UnauthorizedErrorResponse<T>;

function parseResponse<T, S>(response: AxiosResponse<SuccessResponse<T> | ErrorResponse<S>>): T {
  const data = response.data;
  if ("success" in data) {
    return data.body;
  } else {
    throw new ApiError<S>(data);
  }
}

export class ApiError<T> extends Error {
  code: string;
  body: T | undefined;

  constructor(error: ErrorResponse<T>) {
    super(String(error.body));
    this.code = error.code;
    this.body = error.body;
  }

  isFatalError(): boolean {
    return this.code === "FATAL";
  }

  isNotFoundError(): boolean {
    return this.code === "NOT_FOUND";
  }

  isValidationError(): boolean {
    return this.code === "INVALID";
  }

  isOperationalError(): boolean {
    return this.code === "OPERATIONAL";
  }
}

export class Api<T> {
  static token: string | null = null;
  basePath: string;
  params: ParamType = {};
  headers: Headers = {};

  constructor(basePath: string, params: ParamType = {}, headers: Headers = {}) {
    this.basePath = basePath;
    this.params = params;
    this.headers = headers;
  }

  static setJwt(token: string): void {
    this.token = token;
  }

  static async initJwt(): Promise<void> {
    const token = await UserTokenApis.show();
    this.setJwt(token);
  }

  appendPath(path: string): Api<T> {
    return new Api(this.basePath + "/" + path, this.params, this.headers);
  }

  appendParams(params: ParamType): Api<T> {
    return new Api(this.basePath, { ...this.params, ...params }, this.headers);
  }

  withParams(params: ParamType): Api<T> {
    return new Api(this.basePath, params, this.headers);
  }

  withHeaders(headers: Headers): Api<T> {
    return new Api(this.basePath, this.params, headers);
  }

  authorizeWith(jwt: string): Api<T> {
    return this.withHeaders({ Authorization: `Bearer ${jwt}` });
  }

  private buildUrl(): string {
    const params = Object.entries(this.params).reduce((dict, entry) => {
      if (entry[1] !== undefined) {
        Object.assign(dict, { [entry[0]]: entry[1] });
      }
      return dict;
    }, {});
    return this.basePath + "?" + new URLSearchParams(params).toString();
  }

  async post(payload: object, params?: ParamType, extraConfig: Partial<AxiosRequestConfig> = {}): Promise<T> {
    this.authorizeRequest();
    return await parseResponse(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- ignore
      await axios.post(this.buildUrl(), payload, {
        params,
        withCredentials: true,
        headers: this.headers,
        ...extraConfig,
      }),
    );
  }
  // async destroy(): Promise<any> { }

  async get(params?: ParamType): Promise<T> {
    this.authorizeRequest();
    return await parseResponse(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- ignore
      await axios.get(this.basePath, {
        params,
        withCredentials: true,
        headers: this.headers,
      }),
    );
  }

  async put(payload: object, params?: ParamType): Promise<T> {
    this.authorizeRequest();
    return await parseResponse(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- ignore
      await axios.put(this.buildUrl(), payload, {
        params,
        withCredentials: true,
        headers: this.headers,
      }),
    );
  }

  async delete(params: ParamType = {}): Promise<T> {
    this.authorizeRequest();
    return await parseResponse(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- ignore
      await axios.delete(this.basePath, {
        params,
        withCredentials: true,
        headers: this.headers,
      }),
    );
  }

  authorizeRequest(): void {
    if (!Api.token) {
      return;
    }
    Object.assign(this.headers, { Authorization: `Bearer ${Api.token}` });
  }
}

export const ApiBase = new Api("/api");
