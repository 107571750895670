import { type ApolloLink, createHttpLink as create } from "@apollo/client";
import getConfig from "next/config";

export const createHttpLink = (): ApolloLink => {
  const { publicRuntimeConfig } = getConfig();
  return create({
    uri: publicRuntimeConfig?.GRAPHQL_URL || "http://localhost:4000/graphql",
    credentials: "same-origin",
  });
};

export const createPublicHttpLink = (): ApolloLink => {
  const { publicRuntimeConfig } = getConfig();
  return create({
    uri: publicRuntimeConfig?.PUBLIC_GRAPHQL_URL || "http://localhost:4000/graphql-public",
    credentials: "same-origin",
  });
};
