export const uiPalette = {
  red: {
    main: "#DF5475",
    active: "#C0506B",
    secondary: "#DF54752B",
  },
} as const;

type RedUi = `ui.red.${keyof typeof uiPalette.red}`;

export type UiPalette = RedUi;
