import { backgroundPalette, textPalette } from "@/styles/themes/colors/palettes";
import { type ComponentStyleConfig } from "@chakra-ui/react";

export const Tabs: ComponentStyleConfig = {
  variants: {
    enclosed: {
      tab: {
        _selected: {
          bg: backgroundPalette.gray[50],
          color: textPalette.black,
        },
        fontWeight: "bold",
        color: textPalette.gray[3],
        paddingY: "1rem",
        paddingX: "2rem",
      },
    },
  },
};
