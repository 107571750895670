export const backgroundPalette = {
  gray: {
    50: "#FBFBFC",
    100: "#F3F5F5",
    150: "#DADBDD",
    200: "#E5E5E5",
  },
  white: "#FFFFFF",
  lightGreen: "#EDF7EC",
  danger: "#FDF6F8",
};

type BackgroundGray = `bg.gray.${keyof typeof backgroundPalette.gray}`;
type BackgroundWhite = "bg.white";
type BackgroundLightGreen = "bg.lightGreen";

export type BackgroundPalette = BackgroundGray | BackgroundWhite | BackgroundLightGreen;
