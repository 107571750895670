import { type FC, type ReactNode } from "react";
import { useRouter } from "next/router";
import { useCurrentProfile } from "@/pages/_app.page";
import { isAllowedPathForCurrentUser } from "@/utils/authorization";
import { NotFound } from "@/components/NotFound";
import { SpinnerRow } from "@/components/Spinner/SpinnerRow";

export interface GuardProps {
  readonly children: ReactNode;
}

export const AuthGuard: FC<GuardProps> = ({ children }) => {
  const router = useRouter();
  const currentProfile = useCurrentProfile();
  if (isAllowedPathForCurrentUser(router, currentProfile.user)) {
    return <>{children}</>;
  }
  return <NotFound />;
};
