import { ChakraProvider } from "@chakra-ui/react";
import App, { type AppContext } from "next/app";
import { type AppProps } from "next/dist/shared/lib/router/router";
import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { theme } from "@/styles/themes/extend";
import React, { createContext, type FC, useContext, useEffect, useState } from "react";
import { setContext } from "@apollo/client/link/context";
import { UserTokenApis } from "@/api/jwt";
import { Api } from "@/api/base";
import {
  useMeQuery,
  type Maybe,
  type ProfileSearchResultEdge,
  type FeatureFlag,
  type ProfileSearchResult,
  type CurrentUser,
} from "@/generated/graphql.gen";
import { createHttpLink } from "@/utils/graphql";
import TagManager from "react-gtm-module";
import { GtagUtil } from "@/utils/gtag";
import { EventUtil } from "@/utils/event";
import { GlobalStyles } from "@/styles/global";
import { useRouter } from "next/router";
import Head from "next/head";
import { CommonApiUtils } from "@/utils/api";
import { responseContainsAuthError } from "@/utils/errors";
import { NotFound } from "@/components/NotFound";
import { AuthGuard } from "@/components/AuthGuard";
import { SpinnerRow } from "@/components/Spinner/SpinnerRow";
import { ErrorProvider } from "@/components/Errors";

export interface CurrentProfile {
  enabledFeatures: Set<string>;
  user: CurrentUser | null;
  initialized: boolean;
  reset: () => Promise<void>;
}

// TODO: Fragment collocation が実装される時に型定義を見直す
type UserForCurrentProfile = Pick<CurrentUser, "id" | "profilePhotoUrl">;

interface UserProfileForCurrentProfile extends Omit<ProfileSearchResult, "user"> {
  user?: Maybe<UserForCurrentProfile>;
}

interface UserProfileEdgeForCurrentProfile extends Omit<ProfileSearchResultEdge, "node"> {
  node: UserProfileForCurrentProfile;
}

interface CurrentProfiles {
  profiles: UserProfileEdgeForCurrentProfile[];
  setProfiles: (
    value:
      | ((prevState: UserProfileEdgeForCurrentProfile[]) => UserProfileEdgeForCurrentProfile[])
      | UserProfileEdgeForCurrentProfile[],
  ) => void;
}

export const CurrentProfileContext = createContext<CurrentProfile>({
  initialized: false,
  user: null,
  reset: async () => {},
  enabledFeatures: new Set(),
});
const CurrentProfilesContext = createContext<CurrentProfiles>({ profiles: [], setProfiles: () => {} });

export function useCurrentProfile(): CurrentProfile {
  return useContext(CurrentProfileContext);
}

export function useCurrentProfiles(): CurrentProfiles {
  return useContext(CurrentProfilesContext);
}

const AuthProvider: FC<{ initialized: boolean; children: React.ReactNode }> = ({ initialized, children }) => {
  const [completed, setCompleted] = useState(false);
  const [user, setUser] = useState<CurrentUser | null>();
  const [enabledFeatures, setEnabledFeatures] = useState<FeatureFlag[]>([]);
  const result = useMeQuery({
    skip: !initialized,
    onCompleted: (data) => {
      const user = data.me;
      setUser(user);
      setEnabledFeatures(data.enabledFeatures);
      EventUtil.reportUser(user);
      setCompleted(true);
    },
    onError: () => {
      setCompleted(true);
    },
  });
  const isForbidden = responseContainsAuthError(result);
  if (!completed || result.loading) {
    return <SpinnerRow />;
  }
  return (
    <CurrentProfileContext.Provider
      value={{
        user: user || null,
        enabledFeatures: new Set(enabledFeatures.map((v) => v.key)),
        initialized: completed,
        reset: async () => {
          setCompleted(false);
          const newResult = await result.refetch();
          const user = newResult.data.me;
          setUser(user);
          setEnabledFeatures(newResult.data.enabledFeatures);
        },
      }}
    >
      {isForbidden ? <NotFound /> : <AuthGuard>{children}</AuthGuard>}
    </CurrentProfileContext.Provider>
  );
};

export const CurrentProfilesProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [edges, setEdges] = useState<UserProfileEdgeForCurrentProfile[]>([]);
  return (
    <CurrentProfilesContext.Provider value={{ profiles: edges, setProfiles: setEdges }}>
      {children}
    </CurrentProfilesContext.Provider>
  );
};

function MyApp(props: AppProps): JSX.Element {
  const { Component, pageProps } = props;
  const [jwtInitialized, setJwtInitialized] = useState(false);
  const [jwt, setJwt] = useState<string>();
  const router = useRouter();
  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        const jwt = await UserTokenApis.show();
        await Api.initJwt();
        setJwt(jwt);
        setJwtInitialized(true);
      } catch {
        void router.replace(CommonApiUtils.getSignInPageUrl(location.href));
      }
    };
    void init();
    TagManager.initialize({ gtmId: GtagUtil.gtmId() });

    const handleRouteChange = (): void => {
      EventUtil.reportView();
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: jwt ? `Bearer ${jwt}` : "",
      },
    };
  });

  const httpLink = createHttpLink();
  const client = new ApolloClient({
    link: ApolloLink.from([authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
  });

  return (
    <>
      <Head>
        <title>SHEプロフ</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <ApolloProvider client={client}>
        <ChakraProvider theme={theme}>
          <GlobalStyles />
          <ErrorProvider>
            <AuthProvider initialized={jwtInitialized}>
              <CurrentProfilesProvider>
                <Component {...pageProps} />
              </CurrentProfilesProvider>
            </AuthProvider>
          </ErrorProvider>
        </ChakraProvider>
      </ApolloProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default MyApp;
