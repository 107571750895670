import { Center } from "@chakra-ui/react";
import { type VFC } from "react";
import { Spinner } from "..";

export const SpinnerRow: VFC = () => {
  return (
    <Center p="1.25rem">
      <Spinner speed="0.6s" size="lg" thickness="3.2px" />
    </Center>
  );
};
