import { type ReactNode, type VFC, useMemo } from "react";
import { Text, type TextProps } from "@chakra-ui/react";
import { type ChakraColorProps } from "@/styles/themes/colors";

import { type ColorPalettes } from "@/styles/themes/colors/palettes";
import { type TypographyVariant, type TypographyBreakpointsVariant } from "@/components/Typography/type";
import { injectTypographyVariant } from "@/components/Typography/variant";

export interface TypographyProps
  extends Omit<TextProps, ChakraColorProps | "fontSize" | "fontWeight" | "textDecoration" | "variant"> {
  readonly color?: ColorPalettes;
  readonly variant: TypographyVariant | TypographyBreakpointsVariant;
  readonly children: ReactNode;
}

export const Typography: VFC<TypographyProps> = ({ children, variant, color = "text.dark", ...rest }) => {
  const { fontWeight, fontSize, textDecoration, lineHeight } = useMemo(
    () => injectTypographyVariant(variant),
    [variant],
  );
  return (
    <Text
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      textDecoration={textDecoration}
      lineHeight={lineHeight}
      {...rest}
    >
      {children}
    </Text>
  );
};
