import { type ComponentStyleConfig } from "@chakra-ui/react";

export const Select: ComponentStyleConfig = {
  baseStyle: {
    icon: {
      width: "1rem",
      fontSize: "1rem",
      marginRight: "0.5rem",
    },
  },
};
