import { Select } from "@/styles/themes/components/Select";
import { Tabs } from "@/styles/themes/components/Tabs";
import { extendTheme } from "@chakra-ui/react";
import { backgroundPalette, borderPalette, textPalette, themePalette, uiPalette } from "./colors/palettes";
import { fonts } from "./fonts";

// sp = 0px ~ 640px
// tablet = 640px ~ 1200px
// pc = 1200px ~
export const breakpoints = {
  sm: "",
  md: "",
  lg: "",
  xl: "",
  sp: "0px",
  tablet: "640px",
  pc: "1200px",
};

export const theme = extendTheme({
  fonts,
  colors: {
    theme: {
      ...themePalette,
    },
    text: {
      ...textPalette,
    },
    bg: {
      ...backgroundPalette,
    },
    border: {
      ...borderPalette,
    },
    ui: {
      ...uiPalette,
    },
  },
  breakpoints,
  components: {
    Tabs,
    Select,
  },
});
