export const borderPalette = {
  gray: {
    50: "#FBFBFC",
    150: "#DADBDD",
    170: "#E2E4E4",
  },
  red: {
    50: "#E53E3E",
  },
};

type BorderGray = `border.gray.${keyof typeof borderPalette.gray}`;
type BorderRed = `border.red.${keyof typeof borderPalette.red}`;

export type BorderPalette = BorderGray | BorderRed;
