export const themePalette = {
  primary: {
    main: "#4C6072",
    active: "#34414E",
  },
} as const;

type ThemePrimaryPalette = `theme.primary.${keyof typeof themePalette.primary}`;

export type ThemePalette = ThemePrimaryPalette;
