import { type BackgroundPalette } from "./background";
import { type BorderPalette } from "./border";
import { type TextPalette } from "./text";
import { type ThemePalette } from "./theme";
import { type UiPalette } from "./ui";

export * from "./background";
export * from "./border";
export * from "./text";
export * from "./theme";
export * from "./ui";

export type ColorPalettes = BackgroundPalette | BorderPalette | TextPalette | ThemePalette | UiPalette;
