import { MdWarningAmber } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { socialProfileUrl } from "@/utils/url";
import { Box, Image } from "@chakra-ui/react";
import { type VFC } from "react";
import { Typography } from "@/components/Typography";
import { Link } from "@/components/Link";

interface props {
  readonly onClose: () => void;
  readonly children: React.ReactNode;
  readonly variant?: "success" | "error";
}
export const SnackBar: VFC<props> = ({ onClose, children, variant = "success" }) => {
  return (
    <Box
      display="flex"
      gap="6px"
      py="0.75rem"
      px="1rem"
      minW={{ sp: "calc(100% - 3rem)", pc: "265px" }}
      h="3rem"
      mr={{ sp: "1.5rem", pc: "2.5rem" }}
      ml={{ sp: "1.5rem", pc: "0rem" }}
      mb="2.5rem"
      justifyContent={{ pc: "space-between" }}
      alignItems="center"
      boxShadow="2px 2px 12px rgba(0, 0, 0, 0.08)"
      borderRadius="8px"
      bg={variant === "success" ? "bg.lightGreen" : "bg.danger"}
    >
      {variant === "success" ? (
        <FaRegCheckCircle color="#4AAE42" fontSize="1.25rem" />
      ) : (
        <MdWarningAmber color="#DF5475" fontSize="1.25rem" />
      )}
      <Typography variant="body2b" color="theme.primary.main" letterSpacing="1.6px" lineHeight="1.5rem">
        {children}
      </Typography>

      <Box display="flex" alignItems="center" ml={{ sp: "auto", pc: "0px" }} cursor="pointer">
        <Image
          display="inline"
          verticalAlign="middle"
          onClick={onClose}
          src={socialProfileUrl("images/close.svg")}
          w="0.75rem"
          h="0.75rem"
          alt="close-icon"
        />
      </Box>
    </Box>
  );
};
