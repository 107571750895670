import { type QueryResult, type ServerError as ApolloServerError, type OperationVariables } from "@apollo/client";

const ServerErrorCode = Object.freeze({
  NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
});

interface RawServerError {
  message: string;
  extensions?: {
    code?: string;
  };
}

interface ServerError {
  message: string;
  code?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- ignore
const parseServerErrors = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  result: QueryResult<TData, TVariables>,
): ServerError[] => {
  let errors: RawServerError[] = [];
  const networkError = (result.error?.networkError as ApolloServerError | undefined)?.result;
  if (networkError) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ignore
    errors = (networkError as Record<string, any>).errors;
  }

  return errors.map((e) => ({
    code: e.extensions?.code,
    message: e.message,
  }));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- ignore
export const responseContainsAuthError = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  result: QueryResult<TData, TVariables>,
): boolean => {
  const errors = parseServerErrors(result);
  return errors.some((e) =>
    ([ServerErrorCode.NOT_AUTHENTICATED, ServerErrorCode.NOT_AUTHORIZED] as string[]).includes(e.code || ""),
  );
};
