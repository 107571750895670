import getConfig from "next/config";
import { Api } from "@/api/base";
const { publicRuntimeConfig } = getConfig();

export const CommonApiUtils = {
  getEndpointUrl(): string {
    return (publicRuntimeConfig.COMMON_API_URL as string) || "http://localhost:8090";
  },
  getCommonApiUrl() {
    const path = "api/commons/v1";
    return `${this.getEndpointUrl()}/${path.toString()}`;
  },
  getLikesApiUrl() {
    const path = "api/v3";
    return `${this.getEndpointUrl()}/${path.toString()}`;
  },
  getAccountPageUrl(path: string): string {
    return `${publicRuntimeConfig.ACCOUNT_PAGE_URL as string}/${path}`;
  },
  getSignInPageUrl(redirectUrl: string) {
    const params = new URLSearchParams();
    params.append("redirect_url", redirectUrl);
    const path = `sign_in?${params.toString()}`;
    return this.getAccountPageUrl(path);
  },
};

export const UserInfoApis = {
  base() {
    return new Api<void>(CommonApiUtils.getCommonApiUrl()).appendPath("users/current/user_infos");
  },
  async updateProfileImage(profileImage: File): Promise<void> {
    const formData = new FormData();
    formData.append("profile", profileImage);
    await this.base().put(formData);
  },
};
